<template>
  <section>
    <div
      v-for="(element, index) in props.column"
      :key="element.id"
    >
      <T3Renderer :content="[element]" />
      <div
        v-if="props.appearance?.showDivider && index < props.column.length - 1"
        class="h-px w-full bg-white"
      />
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { T3CeBackgroundContainerProps } from './T3CeBackgroundContainer.types'

const props = withDefaults(defineProps<T3CeBackgroundContainerProps>(), {
  column: () => [],
})
</script>
